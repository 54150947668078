
<template>
  <!-- 商品秒杀页 -->
  <div class="commodity-spike" >
    <van-nav-bar
        title="好友帮砍"
      
       
        left-arrow
        @click-left="$goBack()"
        @touchmove.prevent
      />
    <!-- {{data}} -->
    <div class="main" v-if="goodsList!=null">
        <div class="remainning-time"  v-show="goodsList.bargainUser"  > 倒计时：<span>{{ time }}</span></div>
        <div class="goods-list">
          <!-- 秒杀商品列表 -->
          <div class="goods-item">
            <div class="title">
              <!-- 用户点击跳转时间 传店铺id -->
              <img @click="JumpStore()"  v-if="goodsList.shopInfo&&goodsList.shopInfo.imgUrl" class="brand-logo" :src="goodsList.shopInfo.imgUrl" alt="品牌logo" />
              <span class="store-name"> {{ goodsList.shopInfo.name }}</span>
            </div>
            <!-- {{cutFrends}} -->
            <div class="good-desc"> {{ goodsList.name }}</div>
            <div class="join-poeple">
                <span class="poeple-num">已有{{ goodsList.launchNum }}人参加</span>
                <span class="rule" @click="openRule()"> 砍价规则 </span>
            </div>
            <!-- {{$store.state.userDate.id}} -->
            <!-- 砍价规则 -->
            <div class="rule-info" v-show="isShow">
              <div class="rule-box fade-out-fwd">
                <div class="rule-title">  规则说明 <span class="close" @click="closeRule()">X</span></div>
                <div class="rule-content">{{ goodsList.cutRule }}</div>
              </div>
            </div>
            <div class="good-info">
              <img class="good-img" :src="goodsList.picUrl" alt="商品图片" />
              <div class="desc">
                <span class="good-name van-multi-ellipsis--l2">{{ goodsList.name }}</span>
                <span class="specifications">
                  <div class="specifications-item" v-for="item in goodsList.goodsSku.specs" :key="item.id" >{{ item.specValueName }}</div>
                </span>
                <div class="prices">
                  <span class="activeprice">
                    ￥{{ goodsList.bargainPrice }}
                  </span>
                  <span class="orginprice" >￥{{ goodsList.goodsSku.salesPrice }}</span>
                   <div class="lable" v-if=" goodsList.goodsSpu.freightTemplat && goodsList.goodsSpu.freightTemplat.name" >{{ goodsList.goodsSpu.freightTemplat.name }}
                  </div>
                </div>
              </div>
            </div>
          
            <div
              v-if="!goodsList.bargainUser && goodsList.status === '0'"
              class="robbing"
              style="background-color: gery"
            >
              活动已结束
            </div>
            <div
              v-if="!goodsList.bargainUser && goodsList.status === '2'"
              class="robbing"
            >
              活动未开始
            </div>
            <div
              v-if="
                goodsList.bargainUser &&
                goodsList.bargainUser.status === '0' &&
                goodsList.status === '1'
              "
              @click="showSharePual()"
              class="robbing"
            >
              邀请好友砍价
            </div>
            <div v-if=" goodsList.bargainUser &&goodsList.bargainUser.status === '1' && goodsList.status === '1'" class="robbing" style="background-color: #ffc698"  >砍价已完成 </div>
            <div class="time" v-if="goodsList.bargainUser&&goodsList.bargainUser.validBeginTime&&goodsList.bargainUser.validEndTime">{{ goodsList.bargainUser.validBeginTime }}至{{goodsList.bargainUser.validEndTime}}</div>
            <div
            class="bargain-info" v-if="goodsList.bargainUser 
            
            
            ">
            <div class="bargain-data"> 已砍<span>{{ goodsList.bargainUser.havBargainAmount }}</span> 元，还差
                                          <span>{{( goodsList.goodsSku.salesPrice -goodsList.bargainUser.havBargainAmount- goodsList.bargainPrice).toFixed(2)}}</span >元
            </div>
            <div class="proportion">
              <div
                class="proportion-item" :style="{width: ( (goodsList.bargainUser.havBargainAmount / (goodsList.goodsSku.salesPrice- goodsList.bargainPrice)) * 100).toFixed(2) + '%'}">
                <div class="readyhave"> {{ ((goodsList.bargainUser.havBargainAmount / (goodsList.goodsSku.salesPrice - goodsList.bargainPrice)) *100).toFixed(2) + "%"}}</div>
              </div>    
            </div>   
          </div>
          
           
          <div class="over"> 
            <!-- <div class="cut"><a href="YD20210601://">帮砍一刀</a></div>  -->
            <div class="cut"  v-if="!readCut&&goodsList.bargainUser.status == '0' &&goodsList.bargainUser.isBuy=='0'"  @click="fncutAKnife()" >帮砍一刀</div> 
             <!-- &&goodsList.status === '1' -->

            <div class="readCut" v-if="readCut&&goodsList.bargainUser.status == '0' " >已经砍过了</div>
            <div class="readCut" v-if="goodsList.bargainUser.status == '1'&&goodsList.status==='1'" >砍价已完成</div>

                <div class="readCut" v-if="goodsList.bargainUser&& goodsList.bargainUser.status === '2'" >活动已过期</div>

                <div class="readCut" v-if="!goodsList.bargainUser && goodsList.status === '0'" >活动已结束</div>

            

            <div class="personCut" @click="goCut() ">发起新砍价</div>
          </div>
          </div>
          <div class="frends" v-if="cutFrends">
            <div class="title">
              <div class="left"></div>
              砍价亲友团
            </div>
            <ul class="hlep-list" >
              <li class="hlep-item" v-for="item in cutFrends" :key="item.id"  >
                <img class="frends-img" :src="item.headimgUrl" alt="" />
                <div class="frends-name">{{ item.nickName }}</div>
                <div class="frends-help-money"> ￥ <span>{{ item.cutPrice }}</span></div>
              </li>
            </ul>
          </div>
          <div class="footer" v-if="goodsList.goodsSpu.description">
            <div class="title">商品信息</div>
            <div class="richtext" v-html="goodsList.goodsSpu.description">
             
              <span>已经到底了</span>
            </div>
          </div>
        </div>
      </div>
       <van-share-sheet
      v-model="showShare"
      :options="options"
      title="立即分享给好友"
      @select="onSelect"
    />

    <div class="tips-box" v-if="price">
    <div class="tips" >
      <div class="tip" >
        <div class="title">提示</div>
        <span>恭喜为好友砍下{{price.toFixed(2)}}元</span>
        <div class="yes" @click="yes()">我知道了</div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>

// import { Toast } from 'vant';
import {
  BargainDetail,
  helpCut,
  setStatus,
} from "../../api/BargainDetail/BargainDetil";
import {CutAKnife} from '../../api/helpCut/index'
import {user} from '../../api/userInfo/index'

export default {
  data() {
    return {
      price:0,
      show:false,
      money:0,
      readCut:false,
      bargainId:'',
      userId:'',
      haveCut:false,
      goodsList: null, //页面砍价详情数据
      isShow: 0, // 砍价规则的状态
      shareClass:{title:'',picUrl:'',content:'',openUrl:''},
      time: "", //砍价时间倒计时
      cutFrends: null, //帮砍好友列表
      showShare: false, //分享面板的状态
      wx:'',
      machine:'',
      options: [
        //分享面板的数据
        { name: "微信", icon: "wechat" },
        { name: "QQ", icon: "qq" },
      ],
      data:''
    };
  },
  created() {

  
     if(navigator.userAgent.indexOf('万民')==-1){
        this.openApp()
        return
      }
  // let aaa = {...this.$route.query}
  
   this.$store.commit('setSession',this.$route.query.token)
    if(this.$route.query.token===this.$route.query.session){
      this.$router.replace({
        path:'bargainDatil',
        query:{
          id:this.$route.query.id,
          session:this.$route.query.session
        }
      })
    }

 
    this.wx=navigator.userAgent.includes("MicroMessager")
    this.machine=navigator.userAgent.includes("MAC")
     
   
  
     
     this.bargainId= this.$route.query.bargainId 
    this.userId= this.$route.query.bargainUserId 
      this.text=navigator.userAgent
     console.log(this.$store.state.session)
    //判断是否传id过来  没传跳转到砍价列表页
    
    
    //有id则请求详情数据
    this.getBarginInfo(this.bargainId);
  },

  methods: {
   
      fncutAKnife(){
       

        if(!this.$store.state.session&&this.$route.query.bargainUserId){
          return
        }
          CutAKnife({
              bargainUserId:this.$route.query.bargainUserId
          }).then(res=>{
            if(res.data.code===0){ 
              this.price=res.data.data.cutPrice
            }
            // this.$router.go(0)
           
          })
      },
    //显示分享面板
    showSharePual() {
      this.showShare = true;
    },
    //判断用户点击的分享途径
    onSelect(data, index) {
      console.log(data.name, index, 1111);
      if (index === 0) {
        this.shareArea(0);
        return;
      }
      if (index === 1) {
        this.shareArea(2);
      } else {
        this.shareArea(4);
      }
    },

    getTime(index) {
      if (index === this.active) {
        return;
      }
      console.log(index);
      this.active = index;
      this.getActive;
    },
    //砍价的倒计时方法
    getYMDHMS(timestamp) {
      timestamp||0
      timestamp = timestamp.replace(/-/gi, "/");
    
        let nowTime = Date.parse(new Date()) / 1000;
        let endTime = Date.parse(timestamp) / 1000;
        let t = endTime - nowTime;
        let day = Math.floor(t / 60 / 60 / 24);
        let hour = Math.floor((t / 60 / 60) % 24);
        let min = Math.floor((t / 60) % 60);
        let sec = Math.floor(t % 60);
        if (hour < 10) { hour = "0" + hour}
        if (min < 10)  {min = "0" + min }
        if (sec < 10)  {sec = "0" + sec}
         this.time = day + "天" + hour + "时" + min + "分" + sec + "秒";  
         setInterval(()=>{
           
        let nowTime = Date.parse(new Date()) / 1000;
        let endTime = Date.parse(timestamp) / 1000;
        let t = endTime - nowTime;
        let day = Math.floor(t / 60 / 60 / 24);
        let hour = Math.floor((t / 60 / 60) % 24);
        let min = Math.floor((t / 60) % 60);
        let sec = Math.floor(t % 60);
        if (hour < 10) { hour = "0" + hour}
        if (min < 10)  {min = "0" + min }
        if (sec < 10)  {sec = "0" + sec}
         this.time = day + "天" + hour + "时" + min + "分" + sec + "秒";  
         },1000)
    },


yes(){
 
  location.reload()
},
    getId() {
      //用户点击砍价
      
      setStatus({ bargainId: this.goodsList.id }).then((res) => {
         
        console.log(res);
        if (res.data.code === 0) {
          //
         
          if (res.data.data) {
            console.log(res, "点击砍价");
            // this.money=res.data.data
            
          }
        }
      })
     
    },
    
    JumpStore() {
      //获取店铺id 并跳转到店铺首页
    },
    closeRule() {
      //砍价规则的开关
      this.isShow = 0;
    },
    openRule() {
      this.isShow = 1;
    },
    setRem() {
      //设置跟字体大小
      let screenWidth = document.documentElement.getBoundingClientRect().width;
     
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    // 获取砍价商品详情的方法
    getBarginInfo(id) {
      if (!this.$route.query.id) {
        return;
      }
     
      //砍价详情api
     console.log(this.$store.state.session,111111)

      BargainDetail({ bargainId:id }).then((res) => {
        if (res.data.code === 0) {
          this.goodsList = res.data.data;
           helpCut({
                 size:3,
                current: 1,
                bargainUserId: this.goodsList.bargainUser.id,
              }).then((res) => {

               
                 

                 
                if (res.data.code === 0) {
                  console.log(res, "好友帮砍");
                  this.cutFrends =res.data.data.records;
                  if( this.goodsList.bargainUser &&this.goodsList.bargainUser.status === '1' && this.goodsList.status === '1'){ 
                      this.goCut()
                    //
                  }
                 this.$store.commit('setSession',this.$route.query.session)


                   user().then((info)=>{ 
             
               if(JSON.stringify(this.cutFrends).includes(info.data.data.id)){
                  console.log(info.data.data.id,"找到了");
                   
                   this.readCut=true
                  
                   
                    
                  }
      })
               
                }
               
              });
          // console.log(this.goodsList, "砍价详情");
          // console.log(this.$store.state.userDate.id=== this.goodsList.bargainUser.userId,'用户')
            this.getYMDHMS(this.goodsList.validEndTime);
          
        //  if(this.$store.state.userDate.id=== this.goodsList.bargainUser.userId){
        //    this.$router.replace('/bargainDatil?id='+this.bargainId)
          

        //  }
          
        //  if(this.goodsList.status==='2' || this.goodsList.bargainUser.status==='2'){
        //      this.$router.replace('bargain')
        //      return
        //  }

          if( this.goodsList.status === "0" || this.goodsList.status === "1") {
            // setInterval(this.getYMDHMS(this.goodsList.validEndTime),1000)
            
           
          }
          
        }
        
      });
       
    },
   
    goCut(){
        this.$router.push({path:'bargainDatil',query:{id:this.$route.query.id}})
    },
     shareArea(val)
    
    {
       this.shareClass.picUrl=this.goodsList.goodsSpu.picUrls[0]
       if(this.goodsList.goodsSpu.picUrls.length>0)
       {
         this.shareClass.picUrl=this.goodsList.goodsSpu.picUrls[0]
       }else{

        if(this.$store.state.sharePicUrl.length>0){

                this.shareClass.picUrl=this.$store.state.sharePicUrl[0]

        }
        
      }
      
       this.shareClass.platformId=val
       this.shareClass.title=  this.goodsList.name  
       this.shareClass.openUrl=window.location.href
       this.shareClass.content=this.goodsList.shareTitle
      //  let u = navigator.userAgent
      //  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      //  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终

      this.shareInfo(this.shareClass)
    //    try {
         
     
    // //   if(isIOS)
    // //   {
    //   window.webkit.messageHandlers.share.postMessage(JSON.stringify(this.shareClass));   
    // //   }
    // //   if (isAndroid) {
    // //     if(this.$store.state.platformId!='1')
    // //     {
    // //     
    // //     }
    // //      else{
    //       //  window.android.shareImg(this.shareClass.platformId,
    //       //  this.checked.length>0?this.checked[0]:this.$store.state.sharePicUrl.length>0?this.$store.state.sharePicUrl[0]:'')
    // //      }
    // //   } 
    // //  if(window.webkit){
    // //     window.webkit.messageHandlers.share.postMessage(JSON.stringify(this.shareClass))
    // //  }
    //    } catch (error) {
    //      //
    //       window.android.share(this.shareClass.platformId,
    //       '3',
    //       this.shareClass.title,
    //       this.shareClass.content,
    //       this.shareClass.openUrl,
    //       this.share.picUrl
    //     ); 
        
    //    }
    },
   
  },
  mounted() {
      this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
   
   

  
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
    clearInterval(this.time);
  },
};
</script>

<style  scoped>
.commodity-spike {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Medium, PingFang SC;
}
.head {
 display: flex;
 align-items: center;
  width: 100%;
  padding: 0.533333rem .933333rem  0.266667rem  0;
  background-color: white;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  height: auto;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
 
  margin-left: 0.533333rem;
}
.head .title {
flex: 1;
text-align: center;

  
  font-size: 0.453333rem;
  font-weight: 600;
}

.main{
  overflow: auto;
  flex: 1;
   padding:.426667rem;
}

.content {
  position: fixed;
  left: 0;
  top: 1.5rem;
  height: 100%;
  overflow: auto;
 
  -webkit-overflow-scrolling: touch;
}
.content::-webkit-scrollbar {
  display: none;
}
.box {
  padding-bottom: 1.066667rem;
}
.content .list {
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
  color: white;
  margin-bottom: 0.293333rem;
  overflow-x: auto;
}

.remainning-time {
  margin-bottom: 0.26rem ;
  padding:.426667rem ;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  background-color: white;
}
.remainning-time span {
  color: #ff5200ff;
}
.goods-item {
  width: 100%;

  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 auto 0.266667rem;
  padding: 0.373333rem 0.426667rem 0.6rem;
  box-sizing: border-box;
}
.goods-item .title {
  display: flex;
  align-items: center;
}
.title .brand-logo {
  width: 0.666667rem;
  height: 0.666667rem;
}
.title .store-name {
  font-size: 0.4rem;
  font-weight: 600;
  padding-left: 0.266667rem;
}
.good-desc {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.56rem;
  margin-top: 0.24rem;
  margin-bottom: 0.266667rem;
}
.good-info {
  display: flex;
  font-size: 0.4rem;
}
.good-info .good-img {
  width: 2.426667rem;
  height: 2.426667rem;
  border-radius: 0.266667rem;
  box-sizing: border-box;
  margin-right: 0.266667rem;
}
.desc .good-name {
  
  min-height: 1.12rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.186667rem;
}
.desc .activeprice {
  color: #a80000;
  font-weight: 600;
  margin-right: 0.266667rem;
}
.specifications {
  display: flex;
  width: 4.88rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  margin-bottom: 0.106667rem;
  align-items: center;
}
.specifications-item {
  display: inline;
  border-right: 0.013333rem solid #999;
  text-align: center;
  padding: 0 0.066667rem;
}
.specifications-item:nth-last-of-type(1) {
  border-right: none;
}
.desc .orginprice {
  color: #999;
  text-decoration: line-through;
  font-weight: 600;
  margin-bottom: 0.133333rem;
}
.desc .lable {
 
  /* height: 0.426667rem; */
  /* padding: 2px 2px;
  border-radius: 10%; */
  background-color: #ff5200ff;
  text-align: center;
  line-height: 0.426667rem;
  transform: scale(0.83);
  color: white;
  display: inline-block;
  margin-left: 0.266667rem;
}
.join-poeple {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.373333rem;
}
.poeple-num {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.453333rem;
}
.rule {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #005bff;
  line-height: 0.453333rem;
}
.robbing {
  width: 8.24rem;
  height: 0.96rem;
 display: flex;
 align-items: center;
 justify-content: center;
  color: white;
  font-size: 0.4rem;
  font-weight: 500;
  background-color: #e64d42;
  margin: 0.533333rem auto 0.266667rem;
  border-radius: 0.56rem;
}
.time {
  width: 6.72rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  text-align: center;
  width: 100%;
}

.bargain-info {
  padding: 0.4rem 0.853333rem 0;
  margin-bottom:0.46rem;
  font-size: 0.4rem;
}
.bargain-info .bargain-data {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.bargain-info .bargain-data span {
  color: #ff5200ff;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;

  line-height: 0.56rem;
}
.proportion {
  width: 100%;
  margin-top: 0.533333rem;
  height: 0.4rem;
  border-radius: 0.213333rem;
  background: #eaeef5;
}
.readyhave {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.453333rem;
}
.proportion-item {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #e64d42ff;
  border-radius: 0.213333rem;
  overflow: hidden;
}
.over {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.293333rem;
  height: 0.986667rem;
  margin-top: 0.533333rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
   color: white;
}
.over div{
    width:4rem;
    height: .986667rem;
    border-radius: .56rem;
      display: flex;
    align-items: center;
    justify-content: center;
}
.cut{
    background-color: #F5AC4B;
}
.readCut{
    background-color: #FFC698;
}
.personCut{
    margin-left: .293333rem;
    background-color: #4DBCB6;
  
}
.frends {
  padding: 1.146667rem 0.853333rem 0.8rem;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  margin-bottom: 0.26rem;
  border-radius: 0.133333rem;
}

.frends .title {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.frends .left {
  width: 0.16rem;
  height: 0.666667rem;
  background-color: #e64d42ff;
  border-radius: 0.133333rem;
  margin-right: 0.266667rem;
}
.hlep-item {
  display: flex;
  align-items: center;
  padding: 0.373333rem 0;
  border-bottom: 1px solid #d8d8d8;
}

li:nth-last-of-type(1){ 
  border:none
}
.frends-name {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.frends-help-money {
  flex: 1;
  text-align: right;
  color: #e64d42ff;
  font-size: 0.32rem;
  font-weight: 600;
}
.frends-help-money span {
  font-size: 0.426667rem;
  margin-right: 0.426667rem;
}

.frends-img {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  margin-right: 0.266667rem;
}
.footer {
  text-align: center;
  background-color: white;
  padding: 0.27rem 0;
  border-radius: 0.133333rem;
}
.footer .title {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: .666667rem;
}
.richtext {
  width: 100%;
  padding: 0 0.426667rem;
  box-sizing: border-box;
  padding-bottom: 0.666667rem;
  display: flex;
  flex-direction: column;
}
.richtext span {
  margin-top: 0.666667rem;
}
.rule-info {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  z-index: 10000;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.rule-box {
  font-size: 0.426667rem;
  background-color: white;
  width: 80%;
  border-radius: 0.133333rem;
  overflow: hidden;
  
}
.rule-title {
 
  width: 100%;
  text-align: center;
  padding: 0.266667rem 0;
  padding-left:0.533333rem ;
}
.rule-title span {
  float: right;
  margin-right: 0.833333rem;
  color: #ff7200ff;
}
.rule-content {
  height: 100%;
  width: 100%;
  padding-left: 0.426667rem;
  background-color: #ccc;
  box-sizing: border-box;
  padding-top: 0.266667rem;
  font-size: 14px;
}
.tips-box{ 
 position: fixed;
 left: 0;
 top: 0;
 z-index: 1000;
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: rgba(0, 0, 0, 0.5);
}
.tips{
  width: 8.533333rem;
  height:4.906667rem;
  background-color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .453333rem;
  padding-top: .853333rem;
  box-sizing: border-box;
  border-radius: .213333rem;
}
.tips .tip{
  flex: 1;
  background-color: white;
  border-radius: .133333rem;
  text-align: center;
  padding-top: .853333rem 1.333333rem .426667rem ;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tips .tip .title{
  margin-bottom:.453333rem;
  font-size: .453333rem;
font-family: PingFangSC-Medium, PingFang SC;
}
.tips span{
  color: #c5c5c5;
  
  flex: 1;
}
.yes{
  color: #576B95;
  text-align:center;
  padding: .426667rem;
  border-top: 1px solid #E5E5E5;
  

}
/* 
动画
*/
.fade-out-fwd {
  -webkit-animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
</style>